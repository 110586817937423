import { Popover, Transition } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { Fragment } from 'react';
import { Link } from 'react-scroll';
import './App.css';
import graphic from './img/Illustration_modified.svg';
import logoDark from './img/msd-dark.svg';
import logoLight from './img/msd-light.svg';

// const navigation = [
//   { title: 'Company', to: '/company' },
//   { title: 'Contact', to: '/contact' },
// ];

function App() {
  return (
    <div className='relative overflow-hidden'>
      <Popover as='header' className='relative'>
        <div className='pt-6'>
          <nav
            className='relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6'
            aria-label='Global'
          >
            <div className='flex items-center flex-1'>
              <div className='flex items-center justify-between w-full md:w-auto'>
                <Link to='/'>
                  <span className='sr-only'>MSD Labs Inc</span>
                  <img
                    className='h-8 w-auto sm:h-10 cursor-pointer'
                    src={logoLight}
                    alt='MSD Labs Inc logo'
                  />
                </Link>
                <div className='-mr-2 flex items-center md:hidden'>
                  <Popover.Button className='rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-800 focus:outline-none focus:ring-2 focus-ring-inset focus:ring-white'>
                    <span className='sr-only'>Open main menu</span>
                    <MenuIcon className='h-6 w-6' aria-hidden='true' />
                  </Popover.Button>
                </div>
              </div>
              <div className='hidden space-x-8 md:flex md:ml-10'>
                {/* {navigation.map((item) => (
                  <Link
                    className='text-base font-medium text-white hover:text-gray-300 cursor-pointer'
                    to={item.to}
                  >
                    {item.title}
                  </Link>
                ))} */}
              </div>
            </div>
          </nav>
        </div>

        <Transition
          as={Fragment}
          enter='duration-150 ease-out'
          enterFrom='opacity-0 scale-95'
          enterTo='opacity-100 scale-100'
          leave='duration-100 ease-in'
          leaveFrom='opacity-100 scale-100'
          leaveTo='opacity-0 scale-95'
        >
          <Popover.Panel
            focus
            className='absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top md:hidden'
          >
            <div className='rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden'>
              <div className='px-5 pt-4 flex items-center justify-between'>
                <div>
                  <img
                    className='h-8 w-auto'
                    src={logoDark}
                    alt='MSD Labs Inc logo'
                  />
                </div>
                <div className='-mr-2'>
                  <Popover.Button className='bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-600'>
                    <span className='sr-only'>Close menu</span>
                    <XIcon className='h-6 w-6' aria-hidden='true' />
                  </Popover.Button>
                </div>
              </div>
              <div className='pt-5 pb-6'>
                <div className='px-2 space-y-1'>
                  {/* {navigation.map((item) => (
                    <Link
                      className='block px-3 py-2 rounded-md text-base font-medium text-gray-900 hover:bg-gray-50 cursor-pointer'
                      to={item.to}
                    >
                      {item.title}
                    </Link>
                  ))} */}
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>

      <main>
        <div className='pt-10 bg-gray-900 sm:pt-16 lg:pt-8 lg:pb-14 lg:overflow-hidden min-h-screen'>
          <div className='mx-auto max-w-7xl lg:px-8'>
            <div className='lg:grid lg:grid-cols-2 lg:gap-8'>
              <div className='mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center'>
                <div className='lg:py-24'>
                  <h1 className='mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl'>
                    <span className='block'>Web applications</span>
                    <span className='block text-mid-century'>
                      built like it's 2022
                    </span>
                  </h1>
                  <p className='mt-3 text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl'>
                    We specialize in building beautiful, fast, and performant
                    web applications that will exceed expectations and create a
                    lasting impression on users.
                  </p>
                </div>
              </div>
              <div className='mt-12 -mb-16 sm:-mb-48 lg:m-0 lg:relative'>
                <div className='mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0'>
                  {/* Illustration taken from Lucid Illustrations: https://lucid.pixsellz.io/ */}
                  <img
                    className='w-full lg:absolute lg:inset-y-5 lg:left-0 lg:h-full lg:w-lg lg:max-w-none'
                    src={graphic}
                    alt=''
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

      </main>
      <footer className='bg-mid-century'>
        <div className='max-w-7xl mx-auto py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8'>
          <div className='mt-8 md:mt-0 md:order-1'>
            <p className='text-center text-base text-white'>
              &copy; 2022 MSD Labs Inc. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
